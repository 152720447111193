import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import './Brief.css';

gsap.registerPlugin(CSSRulePlugin);

function Brief() {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const imgContainer = imgContainerRef.current;

    const tl = gsap.timeline();
    const rule = CSSRulePlugin.getRule(".img-container:after");

    tl.to("body", { duration: 0.5, autoAlpha: 1 })
      .to(rule, { duration: 1, width: "100%", ease: "Power2.ease" })
      .set(rule, { duration: 0, right: 0, left: "unset" })
      .to(rule, { duration: 1, width: "0%", ease: "Power2.ease" })
      .to("img", { duration: 0.2, opacity: 1, delay: -1 })
      .from(".img-container img", {
        duration: 1,
        scale: 1.4,
        ease: "Power2.easeInOut",
        delay: -1.2
      });

    // Cleanup function
    return () => {
      if (imgContainer) {
        // Any necessary cleanup actions for the ref
      }
    };
  }, []);

  return (
    <div className='brief'>
      <div className='brief-left'>
        <h2 data-animation='paragraph'>WHAT IS BSB MANAGEMENT REALLY ABOUT?</h2>
        <p>We pride ourselves on representing some of the most dynamic talents and groundbreaking projects in the industry. Our expertise spans across music, film, and business management, where we guide our clients towards achieving their fullest potential and securing their place at the top of their fields.</p>
      </div>

      <div className='brief-right' ref={imgContainerRef}>
        <div className="img-container">
          <img src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1727845952/PHOTO-2024-09-27-15-50-30_gp8jwo.jpg' alt='insight_BSB'></img>
        </div>
      </div>
    </div>
  );
}

export default Brief;
