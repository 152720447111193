import React, { useEffect } from 'react';
import './Hero.css';
import { split } from "../../Animations/Text";

function Hero() {
  useEffect(() => {
    // Initialize text animation
    split();
  }, []); // Add an empty dependency array to run once on mount

  return (
    <div className='hero'>
      <p>BSB MANAGEMENT</p>
      <h2 data-animation='header'>TOP MUSIC, FILM & BUSINESS MANAGEMENT AGENCY</h2>
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-film-64_pxg4z7.png' 
        className='reel' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-music-record-96_vairye.png' 
        className='record' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631417/icons8-guitar-96_mdfl54.png' 
        className='mic' 
        alt='niche' 
      />
      <img 
        src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723631288/icons8-microphone-96_jbali8.png' 
        className='guitar' 
        alt='niche' 
      />
    </div>
  );
}

export default Hero;
