import React from 'react'
import Hero from '../../Components/Hero/Hero'
import Brief from '../../Components/Brief/Brief'
import Music from '../../Components/Music/Music'
import Port from '../../Components/Port/Port'
import Ceo from '../../Components/Ceo/Ceo'

function Home() {
  return (
    <div>
      <Hero />
      <Brief />
      <Music />
      <Port />
      <Ceo />
    </div>
  )
}

export default Home