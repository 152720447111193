import React, { useEffect, useState } from "react";
import './Portfolio.css';
import { split } from "../../Animations/Text";

function Portfolio() {
  useEffect(() => {
    split(); // Initialize split text animation
  }, []);

  // State and images for the first work-box (Smartwave)
  const [selectedImgSmartwave, setSelectedImgSmartwave] = useState(0);
  const Smartwave = [
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1723623767/20240814_080254_iprvnt.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726561521/GLtGoilXUAAsirV_ipzwbu.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726561521/GOu3XuEW4AAG8ir_xxczvz.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726561521/GPVKduBW4AEicwW_wltta8.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726561520/F9SYQYsXUAA1Kfj_a1w3ns.jpg"
  ];

  // State and images for the second work-box (Omah Lay)
  const [selectedImgOmahLay, setSelectedImgOmahLay] = useState(0);
  const OmahLay = [
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726573975/GVmCh27XEAAE8Ts_miiejb.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726573808/GHICTgSXAAAk87v_zssfhk.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726573808/GKTpUrQW8AEgRTk_rmqg2j.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726573808/FLA22ubXsAkeEoX_iowtvh.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726573973/GVXMuAcXMAA2su3_mnmc2r.jpg"
  ];

  // State and images for the third work-box (Lekaa Beats)
  const [selectedImgLekaaBeats, setSelectedImgLekaaBeats] = useState(0);
  const LekaaBeats = [
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574314/GW0MDyrWUAAvirZ_kqwna2.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574311/GW0MDyeXYAAXIiq_na2ewf.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574311/GW0MDyQXoAEzY6x_zn8ozo.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574310/GW0MDySXoAA9GUV_pb7fgo.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574310/GFcJV_MWoAADjwM_zebdkw.jpg"
  ];

  // State and images for the fourth work-box (Nonso Amadi)
  const [selectedImgNonsoAmadi, setSelectedImgNonsoAmadi] = useState(0);
  const NonsoAmadi = [
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574788/FeoKml4WQAM7SWT_z0blmn.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574787/Fn0K_ZHWIAA5Kfo_uhc2oz.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574793/Fu50LblakAAXzJk_u7bacu.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574786/GBjLu04XwAAUUK-_idq7bw.jpg",
    "https://res.cloudinary.com/dxnukbo0u/image/upload/v1726574780/FgAk0ErXwAImHuC_yz6np8.jpg"
  ];

  return (
    <div className='portfolio'>
      <div className="talent-profile">
        <h3 data-animation='paragraph'>OUR </h3>
        <h3 data-animation='paragraph'> PORTFOLIO</h3>
      </div>

      <div className='work-con'>
          {/* First work-box (Smartwave) */}
          <div className='work-box'>
              <div className='work-text'>
                  <div>
                    <div className="talent-name">
                      <p> RECORDING ARTISTE</p>
                        <span data-animation='header'>TEMS</span>
                    </div>

                    <div className="class-info">
                      <div className="info-box">
                        <span>YEAR(S)</span>
                        <p>2019 - TILL DATE</p>
                      </div>

                      <div className="info-box">
                        <span>PROJECT(S)</span>
                        <p>3</p>
                      </div>

                      <div className="info-box">
                        <span>TOUR(S)</span>
                        <p>AFRICA, USA, EUROPE, AUSTRALIA</p>
                      </div>

                      <div className="info-box">
                        <span>SALES</span>
                        <p>1 BILLION + STREAMS ACROSS ALL DSP's</p>
                      </div>
                    </div>       
                  </div>

                  <div className="bio-detail">
                    <p>
                      Temilade Openiyi (born June 11, 1995) popularly known as Tems is a Nigerian singer, songwriter, and a producer from Lagos, Nigeria.
                      Tems brings an urban sound that is undeniably unique, embodying a fusion of alternative R&B and Neo-soul genres. Her music influences included Destiny’s Child, Lil Wayne, and Aaliyah.
                    </p>
                  </div>         
              </div>

              <div className='work-img'>
                  <div className='primary-img'>
                      <img src={Smartwave[selectedImgSmartwave]} className="pry" alt="Tems" />
                  </div>

                  <div className='secondary-img'>
                      {Smartwave.map((img, index) => (
                          <img
                              key={index}
                              src={img}
                              className="sec"
                              alt="Tems"
                              onClick={() => setSelectedImgSmartwave(index)}
                          />
                      ))}        
                  </div>
              </div>
          </div>

          {/* Second work-box (Omah Lay) */}
          <div className='work-box'>
              <div className='work-text'>
                  <div>
                    <div className="talent-name">
                      <p> RECORDING ARTISTE</p>
                        <span data-animation='header'>OMAH LAY</span>
                    </div>

                    <div className="class-info">
                      <div className="info-box">
                        <span>YEAR(S)</span>
                        <p>2022 - TILL DATE</p>
                      </div>

                      <div className="info-box">
                        <span>PROJECT(S)</span>
                        <p>2</p>
                      </div>

                      <div className="info-box">
                        <span>TOUR(S)</span>
                        <p>AFRICA, USA, EUROPE</p>
                      </div>

                      <div className="info-box">
                        <span>SALES</span>
                        <p>500 MILLION + STREAMS ACROSS ALL DSP's</p>
                      </div>
                    </div>       
                  </div>

                  <div className="bio-detail">
                    <p>
                      Stanley Omah Didia, known professionally as Omah Lay, is a Nigerian singer, songwriter, and record producer. Omah Lay's music fuses Afrobeat rhythms with soul and pop influences, creating a unique sound that has captivated listeners worldwide.
                    </p>
                  </div>         
              </div>

              <div className='work-img'>
                  <div className='primary-img'>
                      <img src={OmahLay[selectedImgOmahLay]} className="pry" alt="Omah Lay" />
                  </div>

                  <div className='secondary-img'>
                      {OmahLay.map((img, index) => (
                          <img
                              key={index}
                              src={img}
                              className="sec"
                              alt="Omah Lay"
                              onClick={() => setSelectedImgOmahLay(index)}
                          />
                      ))}        
                  </div>
              </div>
          </div>

          {/* Third work-box (Lekaa Beats) */}
          <div className='work-box'>
              <div className='work-text'>
                  <div>
                    <div className="talent-name">
                      <p> MUSIC PRODUCER</p>
                        <span data-animation='header'>LEKAA BEATS</span>
                    </div>

                    <div className="class-info">
                      <div className="info-box">
                        <span>YEAR(S)</span>
                        <p>2018 - TILL DATE</p>
                      </div>

                      <div className="info-box">
                        <span>PROJECT(S)</span>
                        <p>4</p>
                      </div>

                      <div className="info-box">
                        <span>TOUR(S)</span>
                        <p>WORLD TOUR</p>
                      </div>

                      <div className="info-box">
                        <span>SALES</span>
                        <p>800 MILLION + STREAMS ACROSS ALL DSP's</p>
                      </div>
                    </div>       
                  </div>

                  <div className="bio-detail">
                    <p>
                      Lekaa Beats is a Nigerian record producer, known for his diverse range of productions and collaborative efforts with top artists. His beats are a mix of traditional African rhythms with modern beats.
                    </p>
                  </div>         
              </div>

              <div className='work-img'>
                  <div className='primary-img'>
                      <img src={LekaaBeats[selectedImgLekaaBeats]} className="pry" alt="Lekaa Beats" />
                  </div>

                  <div className='secondary-img'>
                      {LekaaBeats.map((img, index) => (
                          <img
                              key={index}
                              src={img}
                              className="sec"
                              alt="Lekaa Beats"
                              onClick={() => setSelectedImgLekaaBeats(index)}
                          />
                      ))}        
                  </div>
              </div>
          </div>

          {/* Fourth work-box (Nonso Amadi) */}
          <div className='work-box'>
              <div className='work-text'>
                  <div>
                    <div className="talent-name">
                      <p> RECORDING ARTISTE</p>
                        <span data-animation='header'>NONSO AMADI</span>
                    </div>

                    <div className="class-info">
                      <div className="info-box">
                        <span>YEAR(S)</span>
                        <p>2016 - 2020</p>
                      </div>

                      <div className="info-box">
                        <span>PROJECT(S)</span>
                        <p>5</p>
                      </div>

                      <div className="info-box">
                        <span>TOUR(S)</span>
                        <p>AFRICA, EUROPE, CANADA</p>
                      </div>

                      <div className="info-box">
                        <span>SALES</span>
                        <p>600 MILLION + STREAMS ACROSS ALL DSP's</p>
                      </div>
                    </div>       
                  </div>

                  <div className="bio-detail">
                    <p>
                      Nonso Amadi is a Nigerian-born Afro-R&B, soul singer, and producer. Known for his soulful vocals and poetic lyrics, Nonso has been a trailblazer in the Afrobeats-influenced R&B space.
                    </p>
                  </div>         
              </div>

              <div className='work-img'>
                  <div className='primary-img'>
                      <img src={NonsoAmadi[selectedImgNonsoAmadi]} className="pry" alt="Nonso Amadi" />
                  </div>

                  <div className='secondary-img'>
                      {NonsoAmadi.map((img, index) => (
                          <img
                              key={index}
                              src={img}
                              className="sec"
                              alt="Nonso Amadi"
                              onClick={() => setSelectedImgNonsoAmadi(index)}
                          />
                      ))}        
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default Portfolio;
