import React, { useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation(); // Get current location to track the active page
  const [activePage, setActivePage] = useState(location.pathname); // Set default active page
  const [showMenu, setShowMenu] = useState(false); // Track menu visibility

  const handleSetActive = (page) => {
    setActivePage(page); // Set the clicked page as active
    setShowMenu(false); // Close the menu after selecting a page
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle menu visibility
  };

  return (
    <div className='navbar'>
      <div className='logo'>
        <Link to={"./"}>
          <img 
            src='https://res.cloudinary.com/dxnukbo0u/image/upload/v1723625586/dCXLeaWpEhHXGq6dpnOTE-transformed_oiyzfe.png' 
            alt='logo' 
          />
        </Link>
      </div>

      <div className='nav-items'>
        <nav>
          <Link to={"./"} onClick={() => handleSetActive("/")}>
            <li className={activePage === "/" ? 'active' : ''}>HOME</li>
          </Link>
          <Link to={"./about"} onClick={() => handleSetActive("/about")}>
            <li className={activePage === "/about" ? 'active' : ''}>ABOUT</li>
          </Link>
          <Link to={"./portfolio"} onClick={() => handleSetActive("/portfolio")}>
            <li className={activePage === "/portfolio" ? 'active' : ''}>PORTFOLIO</li>
          </Link>
        </nav>

        {/* Use <a> tag instead of Link for mailto */}
        <a href="mailto:bsbinsights@gmail.com" target="_blank" rel="noopener noreferrer">
          <button className='nav-btn'>GET IN TOUCH</button>
        </a>

        <div className='menu-toggle' onClick={toggleMenu}>
          <div className={showMenu ? "hamBox hamBoxOpen" : "hamBox"}>
            <span className={showMenu ? "lineTop spin" : "lineTop"}></span>
            <span className={showMenu ? "lineBottom spin" : "lineBottom"}></span>
          </div>
        </div>

        {showMenu && (
          <div className="fixed-component">
            <div className='menu-con'>
              <p>MENU</p>
              <Link to={"./"} onClick={() => handleSetActive("/")}>
                <li className={activePage === "/" ? 'active' : ''}>HOME</li>
              </Link>
              <Link to={"./about"} onClick={() => handleSetActive("/about")}>
                <li className={activePage === "/about" ? 'active' : ''}>ABOUT</li>
              </Link>
              <Link to={"./portfolio"} onClick={() => handleSetActive("/portfolio")}>
                <li className={activePage === "/portfolio" ? 'active' : ''}>PORTFOLIO</li>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;

